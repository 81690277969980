<template>
  <ValidationObserver ref="obs">
    <div class="p-20">
      <v-form :disabled="!canUpdateVehicleCapture">
        <v-row>
          <v-col cols="12"
                 sm="12">
            <sb-input label="Driver Allowance">
              <v-radio-group v-model="vehicle.driverInformation.driverAllowance"
                             row
                             disabled="true"
                             @change="driverAllowanceChanged">
                <sb-radio-button label="Yes" />
                <sb-radio-button label="No" />
              </v-radio-group>
            </sb-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformation.driverInitials"
                           label="Driver Initials" />
          </v-col>
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformation.driverName"
                           label="Driver Name"
                           rules="required" />
          </v-col>
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="driverID"
                           label="Driver ID"
                           :rules="isIDRequired"
                           :maxlength="13" />
            <span v-if="msg.driverID"
                  style="color:#d0021b">{{ msg.driverID }}</span>
          </v-col>
          <v-col cols="12"
                 sm="3">
            <sb-date-picker-field v-model="vehicle.driverInformation.dateOfBirth"
                                  label="Date of Birth"
                                  ref-name="driverDateOfBirth" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformationdriverPassport"
                           label="Foreign Passport No"
                           :rules="isPassportRequired" />
          </v-col>
          <v-col cols="12"
                 sm="3">
            <sb-select v-model="vehicle.driverInformation.passportCountry"
                       :items="countries"
                       label="Passport Country"
                       item-value="key"
                       item-text="value"
                       :rules="isPassportRequired" />
          </v-col>

          <v-col cols="12"
                 sm="3">
            <sb-select v-model="vehicle.driverInformation.countryCode"
                       :items="countryCodes"
                       label="Country Code"
                       :rules="isRequired" />
          </v-col>
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformation.cellphoneNumber"
                           label="Cellphone Number"
                           :maxlength="10"
                           :rules="isRequired" />
          </v-col>
        </v-row>
        <v-row v-if="driverAllowanceSelected">
          <v-col cols="
                   12"
                 sm="3">
            <sb-select v-model="vehicle.driverInformation.allowanceTypes"
                       :items="allowanceTypes"
                       label="Driver Allowance Type"
                       item-value="key"
                       item-text="value"
                       :rules="isRequired"
                       @change="allowanceTypeChange" />
          </v-col>

          <v-col v-if="showDriverBankingDetals"
                 cols="12"
                 sm="3">
            <sb-currency-field v-model="vehicle.driverInformation.amount"
                               :allow-negative="false"
                               label="Amount"
                               hide-details
                               :rules="isRequired" />
          </v-col>
        </v-row>
        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="12">
            <sb-input label="Excess Above Allowance" />
            <v-radio-group v-model="vehicle.driverInformation.excessAboveAllowance"
                           row
                           @change="excessAboveAllowanceChanged()">
              <sb-radio-button label="Yes" />
              <sb-radio-button label="No" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="3">
            <sb-select v-model="vehicle.driverInformation.paymentMethod"
                       :items="paymentMethods"
                       label="Payment Method"
                       item-value="key"
                       item-text="value"
                       :rules="bankingRequired"
                       @change="paymentMethodChanged(vehicle.driverInformation.paymentMethod)" />

            <span v-if="showDriverContributionMessage && driverAllowanceSelected"
                  style="color:#d0021b">DRIVER CONTRIBUTION SELECTED -METHOD MUST BE VIA ACB</span>
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected"
               align="center">
          <v-col cols="12"
                 sm="6">
            <sb-vehicle-domicile-search ref="SbVehicleDomicileSearch"
                                        v-model="vehicle.driverInformation.abcDomicile"
                                        :rules="bankingRequired"
                                        label="ACB Domicile"
                                        :domicile-default-value="vehicle.driverInformation.abcDomicile"
                                        ref-name="abcDomicile"
                                        @domicile-description="selectedDomicile" />
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformation.account"
                           label="Account"
                           :rules="bankingRequired" />
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="accountType"
                           label="Account Type"
                           :rules="bankingRequired"
                           disabled="true" />
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="3">
            <sb-text-field v-model="vehicle.driverInformation.accountName"
                           label="Account Name"
                           :rules="bankingRequired" />
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="4">
            <v-file-input v-model="proofOfbanking"
                          clearable
                          label="Proof of Banking Details"
                          variant="outlined"
                          prepend-icon=""
                          prepend-inner-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                          @change="uploadFile">
              <template #selection="{text}">
                <v-chip color="secondary"
                        dark
                        label
                        small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="3">
            <sb-input label="Driver Allowance Options" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 sm="3">
            <span v-if="driverAllowanceOptionsListFilled && driverAllowanceSelected"
                  style="color:#d0021b"> INVALID - NO ALLOWANCE OPTION HAS BEEN SELECTED </span>
          </v-col>
        </v-row>
        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="6">
            <sb-input label="Complete the following" />
          </v-col>
        </v-row>

        <v-row v-if="driverAllowanceSelected">
          <v-col cols="12"
                 sm="12">
            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Fuel" />
                <v-radio-group v-model="vehicle.driverInformation.fuel"
                               row
                               :disabled="driverOptionsEditable"
                               @change="fuelOptionsChanged(vehicle.driverInformation.fuel)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Toll/Parking Fees" />
                <v-radio-group v-model="vehicle.driverInformation.tollPackingFees"
                               row
                               :disabled="driverOptionsEditable"
                               @change="tollOptionsChanged(vehicle.driverInformation.tollPackingFees)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Funding Interest" />
                <v-radio-group v-model="vehicle.driverInformation.fundingInterest"
                               row
                               :disabled="driverOptionsEditable"
                               @change="fundingChanged(vehicle.driverInformation.fundingInterest)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Fleetsaver" />
                <v-radio-group v-model="vehicle.driverInformation.fleetSaver"
                               row
                               :disabled="driverOptionsEditable"
                               @change="fleetChanged(vehicle.driverInformation.fleetSaver)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>

              <v-col cols="12"
                     sm="4">
                <sb-input label="Lost Card Ins." />
                <v-radio-group v-model="vehicle.driverInformation.lostCardIn"
                               row
                               :disabled="driverOptionsEditable"
                               @change="lostCardInsChanged(vehicle.driverInformation.lostCardIn)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Maintenance Fund" />
                <v-radio-group v-model="vehicle.driverInformation.maintananceFund"
                               row
                               :disabled="driverOptionsEditable"
                               @change="maintananceFundChanged(vehicle.driverInformation.maintananceFund)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Transfer Fee" />
                <v-radio-group v-model="vehicle.driverInformation.transferFee"
                               row
                               :disabled="driverOptionsEditable"
                               @change="transferFeeChanged(vehicle.driverInformation.transferFee)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Top Up Oil" />
                <v-radio-group v-model="vehicle.driverInformation.topUpOil"
                               row
                               :disabled="driverOptionsEditable"
                               @change="topUpOilChanged(vehicle.driverInformation.topUpOil)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="MISCELLANEOUS" />
                <v-radio-group v-model="vehicle.driverInformation.miscellaneous"
                               row
                               :disabled="driverOptionsEditable"
                               @change="miscellaneousChnaged(vehicle.driverInformation.miscellaneous)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Transaction Charge" />
                <v-radio-group v-model="vehicle.driverInformation.transactionCharges"
                               row
                               :disabled="driverOptionsEditable"
                               @change="transactionChargesChanged(vehicle.driverInformation.transactionCharges)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Admin Fee" />
                <v-radio-group v-model="vehicle.driverInformation.adminFee"
                               row
                               :disabled="driverOptionsEditable"
                               @change="adminFeeChanged(vehicle.driverInformation.adminFee)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Lost Card Charge" />
                <v-radio-group v-model="vehicle.driverInformation.lostCardCharge"
                               row
                               :disabled="driverOptionsEditable"
                               @change="lostCardChanged(vehicle.driverInformation.lostCardCharge)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Allowance Fee" />
                <v-radio-group v-model="vehicle.driverInformation.allowanceFee"
                               row
                               :disabled="driverOptionsEditable"
                               @change="allowanceFeeChanged(vehicle.driverInformation.allowanceFee)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Short-Term Rental" />
                <v-radio-group v-model="vehicle.driverInformation.shortTermRental"
                               row
                               :disabled="driverOptionsEditable"
                               @change="shortRentalChanged(vehicle.driverInformation.shortTermRental)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Accident Repair" />
                <v-radio-group v-model="vehicle.driverInformation.accidentRepair"
                               row
                               :disabled="driverOptionsEditable"
                               @change="accidentRepairChanged(vehicle.driverInformation.accidentRepair)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Capture Charge" />
                <v-radio-group v-model="vehicle.driverInformation.captureCharge"
                               row
                               :disabled="driverOptionsEditable"
                               @change="captureChargeChanged(vehicle.driverInformation.captureCharge)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Turnkey Fee" />
                <v-radio-group v-model="vehicle.driverInformation.turnKeyFee"
                               row
                               :disabled="driverOptionsEditable"
                               @change="turnkeyFeeChanged(vehicle.driverInformation.turnKeyFee)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Lost Wallet Charge" />
                <v-radio-group v-model="vehicle.driverInformation.lostWalletCharge"
                               row
                               :disabled="driverOptionsEditable"
                               @change="lostWalletChanged(vehicle.driverInformation.lostWalletCharge)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Stamp Duty" />
                <v-radio-group v-model="vehicle.driverInformation.stampDuty"
                               row
                               :disabled="driverOptionsEditable"
                               @change="stampDutyChanged(vehicle.driverInformation.stampDuty)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
              <v-col cols="12"
                     sm="4">
                <sb-input label="Refund Bank Charge" />
                <v-radio-group v-model="vehicle.driverInformation.refundBankCharge"
                               row
                               :disabled="driverOptionsEditable"
                               @change="refundBankChanged(vehicle.driverInformation.refundBankCharge)">
                  <sb-radio-button label="Yes" />
                  <sb-radio-button label="No" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </validationobserver>
</template>

<script>
import countriesFile from '@/config/countries.json';
import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
// import { DOC_TYPE } from '@/config/constants';
import SbVehicleDomicileSearch from './SbVehicleDomicileSearch';

export default {
  components: {
    ValidationObserver,
    SbVehicleDomicileSearch,
  },

  props: {
    vehicleEntity: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      vehicle: this.vehicleEntity,

      canUpload: true,

      paymentMethods: ['', 'Monthly Soonest Day ACB', 'Monthly ACB 5 Day', 'No Payment'],
      countries: [],
      allowanceTypes: ['', 'Company Contribution', 'CPK Allowance', 'Driver Contribution'],
      driverBilling: ['', 'Yes', 'No'],
      driverAllowanceSelected: false,
      countryCodes: ['', 'South Africa +27', 'Botswana +267', 'Namibia +264', 'Zimbabwe +263', 'Lesotho +266', 'Mozambique +258'],
      isRequired: '',
      selectedACBDomicile: '',
      searchDomicileString: '',
      isIDRequired: '',
      isPassportRequired: '',
      updatedDocuments: [],
      driverID: '',
      driverPassport: '',
      msg: [],
      proofOfbanking: [],
      showDriverBankingDetals: false,
      driverAllowanceOptionsSelected: false,
      driverAllowanceOptionsListFilled: false,
      driverAllowanceOptionsList: [],
      bankingRequired: '',
      acbBranches: [],
      bankNames: [],
      accountTypes: [],
      showDriverContributionMessage: false,
      accountType: 'CURRENT ACCOUNT',
      driverOptionsEditable: false,
      disableDriverPage: true,
      requiredRule: [(v) => !!v || 'Item is required'],
      label: 'ACB - Domicile',
      error: false,
    };
  },

  watch: {
    async searchDomicileString(value) {
      if (value.length > 3) {
        if (!value.includes('-')) {
          this.getVehiclesDomicile(value);
        }
      }
    },

    driverID(value) {
      if (value !== '') {
        this.validateID(value);
        this.isPassportRequired = '';
      }
      this.vehicle.driverInformation.driverID = value;
    },
    proofOfbanking(value) {
      this.vehicle.driverInformation.proofOfbanking = value;
    },
    driverPassport(value) {
      this.driverPassport = value;
      if (this.driverAllowanceSelected) {
        if (value !== '') {
          // set id to not required and country to required
          // validate passport
          this.isIDRequired = '';
        }
      }
    },

    driverAllowanceOptionsList() {
      if (this.driverAllowanceOptionsList.length === 20) {
        for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
          if (this.driverAllowanceOptionsList[index].includes('0')) {
            this.driverAllowanceOptionsListFilled = false;
            break;
          } else {
            this.driverAllowanceOptionsListFilled = true;
          }
        }
      }
    },

    'vehicle.driverInformation.driverAllowance': {
      handler(value) {
        if (value === 1) {
          this.driverAllowanceSelected = false;
          this.isIDRequired = '';
          this.isPassportRequired = '';
          this.isRequired = '';
        } else {
          this.driverAllowanceSelected = true;
          this.isIDRequired = 'required';
          this.isPassportRequired = 'required';
          this.isRequired = 'required';
        }
      },
    },
  },

  mounted() {
    this.loadCountries();

    this.loadClieintUninvolvedDetails();
    this.checckDriverAllowance();
    this.getClientVehicleIndicators();
    this.checkDriverId();
    this.checkUploadedFile();
    this.checkAllowance();
  },

  methods: {
    ...mapActions('vehicles', ['getGeneralCodes', 'searchVehiclesDomiciles']),
    ...mapActions('clients', ['getClieintUninvolvedDetails', 'getVehicleIndicators']),

    async getClientVehicleIndicators() {
      const vehicleIndicators = await this.getVehicleIndicators(this.clientId);

      if (vehicleIndicators.clientVehicleIndicators !== null) {
        if (this.vehicle.productSetup.driverAllowance === 'Y') {
          this.vehicle.driverInformation.driverAllowance = 0;
        } else if (this.vehicle.productSetup.driverAllowance === 'N') {
          this.vehicle.driverInformation.driverAllowance = 1;
        }
      }
    },

    checckDriverAllowance() {
      if (this.vehicle.driverInformation.driverAllowance != null) {
        if (this.vehicle.driverInformation.driverAllowance === 1) {
          this.driverAllowanceSelected = false;
          this.isIDRequired = '';
          this.isPassportRequired = '';
          this.isRequired = '';
        } else {
          this.driverAllowanceSelected = true;
          this.isIDRequired = 'required';
          this.isPassportRequired = 'required';
          this.isRequired = 'required';
        }
      }
    },
    checkDriverId() {
      if (this.vehicle.driverInformation.driverID != null) {
        this.driverID = this.vehicle.driverInformation.driverID;
      }
    },

    uploadFile() {
      // Capture the selected file when the input changes
      this.vehicle.driverInformation.proofOfbanking = this.proofOfbanking;
    },
    checkUploadedFile() {
      if (this.vehicle.driverInformation.proofOfbanking != null) {
        this.proofOfbanking = this.vehicle.driverInformation.proofOfbanking;
      }
    },
    checkAllowance() {
      if (
        this.vehicle.driverInformation.allowanceTypes === 'CPK Allowance' ||
        this.vehicle.driverInformation.allowanceTypes === 'Driver Contribution'
      ) {
        this.showDriverBankingDetals = true;
      }
    },
    fuelOptionsChanged(value) {
      const string = '^Fuel-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`Fuel-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `Fuel-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    tollOptionsChanged(value) {
      const string = '^Toll-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`Toll-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `Toll-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    fundingChanged(value) {
      const string = '^Funding-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`Funding-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `Funding-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    fleetChanged(value) {
      const string = '^Fleet-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`Fleet-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `Fleet-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    lostCardInsChanged(value) {
      const string = '^LostCardIns-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`LostCardIns-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `LostCardIns-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    maintananceFundChanged(value) {
      const string = '^MaintananceFund-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`MaintananceFund-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `MaintananceFund-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    transferFeeChanged(value) {
      const string = '^TransferFee-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`TransferFee-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `TransferFee-${value}`;
        }
      }

      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    topUpOilChanged(value) {
      const string = '^TopUpOil-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`TopUpOil-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `TopUpOil-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    miscellaneousChnaged(value) {
      const string = '^MISCELLANEOUS-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`MISCELLANEOUS-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `MISCELLANEOUS-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    transactionChargesChanged(value) {
      const string = '^TransactionCharges-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`TransactionCharges-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `TransactionCharges-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    adminFeeChanged(value) {
      const string = '^AdminFee-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`AdminFee-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `AdminFee-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    lostCardChanged(value) {
      const string = '^LostCard-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`LostCard-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `LostCard-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    allowanceFeeChanged(value) {
      const string = '^AllowanceFee-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`AllowanceFee-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `AllowanceFee-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    shortRentalChanged(value) {
      const string = '^ShortRental-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`ShortRental-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `ShortRental-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    accidentRepairChanged(value) {
      const string = '^AccidentRepair-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`AccidentRepair-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `AccidentRepair-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    captureChargeChanged(value) {
      const string = '^CaptureCharge-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`CaptureCharge-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `CaptureCharge-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    turnkeyFeeChanged(value) {
      const string = '^TurnkeyFee-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`TurnkeyFee-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `TurnkeyFee-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    lostWalletChanged(value) {
      const string = '^LostWallet-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`LostWallet-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `LostWallet-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    stampDutyChanged(value) {
      const string = '^StampDuty-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`StampDuty-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `StampDuty-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },
    refundBankChanged(value) {
      const string = '^RefundBank-';
      const regexp = new RegExp(string);

      this.driverAllowanceOptionsList.push(`RefundBank-${value}`);

      for (let index = 0; index < this.driverAllowanceOptionsList.length; index += 1) {
        if (regexp.test(this.driverAllowanceOptionsList[index])) {
          this.driverAllowanceOptionsList[index] = `RefundBank-${value}`;
        }
      }
      this.driverAllowanceOptionsList = [...new Set(this.driverAllowanceOptionsList)];
    },

    async loadAccountTypes() {
      const code = '001';
      const accoutTypesTemp = await this.getGeneralCodes(code);

      if (accoutTypesTemp !== null) {
        for (let i = 0; i < accoutTypesTemp.generalCodes.length; i += 1) {
          this.accountTypes.push(accoutTypesTemp.generalCodes[i].description);
          // this.vehicleCategories.sort();
        }
      } else {
        this.accountTypes = [];
      }
    },

    async getVehiclesDomicile(value) {
      const tempResults = await this.searchVehiclesDomiciles(value);
      this.acbBranches = [];
      if (tempResults !== null) {
        if (tempResults.length === 1) {
          this.acbBranches.push(tempResults[0].fullname);
          this.vehicle.driverInformation.acbDomicile = tempResults[0].fullname;
        } else {
          for (let index = 1; index < tempResults.length; index += 1) {
            this.acbBranches.push(tempResults[index].fullname);
          }
        }
      }
    },

    paymentMethodChanged(value) {
      if (value === 'No Payment' && this.vehicle.driverInformation.allowanceTypes === 'Driver Contribution') {
        this.showDriverContributionMessage = true;
      } else {
        this.showDriverContributionMessage = false;
      }
    },

    resetBankingDetails() {
      this.selectedDomicile(null);
      this.vehicle.driverInformation.paymentMethod = '';
      this.vehicle.driverInformation.acbDomicile = '';
      this.vehicle.driverInformation.account = '';
      this.vehicle.driverInformation.accountType = '';
      this.vehicle.driverInformation.accountName = '';
      // this.proofOfbanking = [];
    },
    excessAboveAllowanceChanged() {
      this.resetBankingDetails();
      this.bankingRequired = '';
      // 1 is No and 0 is Yes
      if (this.vehicle.driverInformation.allowanceTypes === 'Company Contribution' && this.vehicle.driverInformation.excessAboveAllowance === 1) {
        this.bankingRequired = '';
      } else if (this.vehicle.driverInformation.allowanceTypes === 'CPK Allowance' && this.vehicle.driverInformation.excessAboveAllowance === 1) {
        this.bankingRequired = '';
      } else if (
        this.vehicle.driverInformation.allowanceTypes === 'Driver Contribution' &&
        this.vehicle.driverInformation.excessAboveAllowance === 1
      ) {
        this.bankingRequired = 'required';
      } else if (
        this.vehicle.driverInformation.allowanceTypes === 'Company Contribution' &&
        this.vehicle.driverInformation.excessAboveAllowance === 0
      ) {
        this.bankingRequired = 'required';
      } else if (this.vehicle.driverInformation.allowanceTypes === 'CPK Allowance' && this.vehicle.driverInformation.excessAboveAllowance === 0) {
        this.bankingRequired = 'required';
      } else if (
        this.vehicle.driverInformation.allowanceTypes === 'Driver Contribution' &&
        this.vehicle.driverInformation.excessAboveAllowance === 0
      ) {
        this.bankingRequired = 'required';
      }
    },

    allowanceTypeChange() {
      this.vehicle.driverInformation.excessAboveAllowance = '';
      this.vehicle.driverInformation.amount = 'R 0.00';
      this.resetBankingDetails();
      if (this.vehicle.driverInformation.allowanceTypes === 'Company Contribution') {
        this.showDriverBankingDetals = false;
      } else {
        this.showDriverBankingDetals = true;
      }
    },
    loadCountries() {
      const tempCountries = [];
      for (let i = 0; i < countriesFile.length; i += 1) {
        if (i === 0) {
          tempCountries.push('');
        }
        tempCountries.push(countriesFile[i].name);
      }
      this.countries = tempCountries;
    },

    validateID(value) {
      this.vehicle.driverInformation.dateOfBirth = '';
      this.msg.driverID = '';
      this.validateSAID(value);
      this.extractBirthdate(value);
    },
    validateSAID(idNumber) {
      // Check if the ID number is 13 digits long
      if (idNumber.length !== 13) {
        this.msg.driverID = 'ID Number should be 13 characters long';
      }

      // Check if all characters are numeric
      if (!/^\d+$/.test(idNumber)) {
        this.msg.driverID = 'ID Number should be bumbers only';
      }

      // If all checks pass, the ID number is valid
      return true;
    },

    driverAllowanceChanged() {
      this.driverID = '';
      this.vehicle.driverInformation.dateOfBirth = '';
      if (this.vehicle.driverInformation.driverAllowance === 1) {
        this.driverAllowanceSelected = false;
        this.isIDRequired = '';
        this.isPassportRequired = '';
        this.isRequired = '';
      } else {
        this.driverAllowanceSelected = true;
        this.isIDRequired = 'required';
        this.isPassportRequired = 'required';
        this.isRequired = 'required';
      }
    },

    extractBirthdate(idNumber) {
      // Extract the first 6 digits from the ID number
      if (idNumber > 6) {
        const birthdateDigits = idNumber.substring(0, 6);

        // Get the year, month, and day from the extracted digits
        const year = birthdateDigits.substring(0, 2);
        const month = birthdateDigits.substring(2, 4);
        const day = birthdateDigits.substring(4, 6);

        // Add the century to the year (assumed 1900 for those born before 2000, and 2000 for those born after)
        const fullYear = year >= 0 && year <= 21 ? `20${year}` : `19${year}`;

        // Create a JavaScript Date object
        const birthdate = new Date(fullYear, month - 1, day);

        // Format the date to "YYYY-MM-DD"
        const formattedBirthdate = birthdate.toLocaleDateString('en-CA');
        this.vehicle.driverInformation.dateOfBirth = formattedBirthdate; // Adjust the locale as needed
      } else {
        this.vehicle.driverInformation.dateOfBirth = '';
      }
    },

    async loadClieintUninvolvedDetails() {
      this.driverOptionsEditable = false;
      const tempResults = await this.getClieintUninvolvedDetails(this.clientId);

      if (tempResults.uninvolved !== null) {
        if (tempResults.uninvolved === 'Y') {
          this.driverOptionsEditable = true;
          this.vehicle.driverInformation.fuel = 0;
          this.vehicle.driverInformation.tollPackingFees = 0;
          this.vehicle.driverInformation.fundingInterest = 0;
          this.vehicle.driverInformation.fleetSaver = 0;
          this.vehicle.driverInformation.lostCardIn = 0;
          this.vehicle.driverInformation.maintananceFund = 0;
          this.vehicle.driverInformation.transferFee = 0;
          this.vehicle.driverInformation.topUpOil = 0;
          this.vehicle.driverInformation.miscellaneous = 0;
          this.vehicle.driverInformation.transactionCharges = 0;
          this.vehicle.driverInformation.adminFee = 0;
          this.vehicle.driverInformation.lostCardCharge = 0;
          this.vehicle.driverInformation.allowanceFee = 0;
          this.vehicle.driverInformation.shortTermRental = 0;
          this.vehicle.driverInformation.accidentRepair = 0;
          this.vehicle.driverInformation.captureCharge = 0;
          this.vehicle.driverInformation.turnKeyFee = 0;
          this.vehicle.driverInformation.lostWalletCharge = 0;
          this.vehicle.driverInformation.stampDuty = 0;
          this.vehicle.driverInformation.refundBankCharge = 0;
        } else if (tempResults.uninvolved === 'N') {
          this.driverOptionsEditable = false;
        }
      }
    },
    canUpdateVehicleCapture() {
      return this.vehicle.requestStatus === ' In-Progress ';
    },
    clearError() {
      this.error = false;
    },

    selectedDomicile(domicile) {
      if (domicile) {
        this.vehicle.driverInformation.abcDomicile = domicile.fullname;
      } else if (domicile === null) {
        this.$refs.SbVehicleDomicileSearch.resetSelectedDomicile();
        this.vehicle.driverInformation.acbDomicile = '';
      }
    },
  },
};
</script>
